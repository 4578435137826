import { html } from 'htm/preact'
import { connect } from 'unistore/preact'
import { useMedia } from 'services/hooks'
import logo from 'assets/streamlabs.svg'
import Navigation from './navigation'
import Notification from 'modules/notification'

const Header = ({ user }) => {
  const mini = useMedia('(max-width: 600px)') && user.isAuthenticated

  return html`
  <header id='header' role='presentation'>
    <a href='/' id='identity' native>
       <${logo} id='streamlabs' viewBox=${mini ? '0 0 100 145' : '0 0 648 145'}/>
    </a>
    <${Navigation} />
    <${Notification}/>
  </header>
 `
}
export default connect('user')(Header)
