import { html } from 'htm/preact'
import { connect } from 'unistore/preact'
import classnames from 'classnames'
import { actions } from './redux'
import messages from './messages'

const Notification = ({ notice, clear }) => {
  const { type, code } = notice

  if (!type) return null
  return html`
    <div class=${classnames('notification', type)}>
      <div class='wrap'>
        ${messages[code]}
        <button type='button' onClick=${clear}>x</button>
      </div>
    </div>
    `
}

export default connect('notice', actions)(Notification)
