
export function getAuthenticatedUser (cognitoUser) {
  const { signInUserSession: { idToken: { payload: cognitoPayload } }, username } = cognitoUser

  const { sub: id, email } = cognitoPayload

  return {
    id,
    email,
    username
  }
}
export function dispatch (state, payload) {
  return merge(state, payload)
}

export function merge (state, next) {
  const prime = state

  for (const property in next) {
    prime[property] = {
      ...prime[property],
      ...next[property]
    }
  }

  return {
    ...state,
    ...prime
  }
}
