export default {
  amplifyConfig: {
    Auth: {
      region: process.env.STREAMLABS_CUSTOMER_APP_AWS_REGION,
      userPoolId: process.env.STREAMLABS_CUSTOMER_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.STREAMLABS_CUSTOMER_APP_AWS_CLIENT_ID,
      identityPoolId: process.env.STREAMLABS_CUSTOMER_APP_AWS_IDENTITY_POOL_ID,
      mandatorySignIn: true
    },
    API: {
      endpoints: [
        {
          name: 'stream-api',
          endpoint: process.env.STREAMLABS_CUSTOMER_APP_STREAM_API_HOST
        }
      ]
    }
  }
}
