import { html } from 'htm/preact'
export default {
  InvalidEmailException: html`<p>Please provide a valid email address.</p>`,
  NoPasswordProvidedException: html`<p>Please provide a password.</p>`,
  NoCurrentPasswordProvidedException: html`<p>Please provide your current password.</p>`,
  InvalidPasswordException: html`<p>Wrong or invalid passwords provided.</p>`,
  InvalidParameterException: html`<p>Wrong or invalid passwords provided.</p>`,
  TermsNotAcceptedException: html`<p>You need to accept the Terms of Service and Privacy Policy.</p>`,
  CodeMismatchException: html`<p>Invalid code provided, please try again</p>`,
  NoEmailPasswordProvidedException: html`<p>Oops, No User with that email was found, <br />Please check your email.</p>`,
  UserNotFoundException: html`<p>Oops, No User with that email was found, <br />Please check your email.</p>`,
  NotAuthorizedException: html`<p>Oops, Wrong password. Try again</p>`,
  EmptyPasswordException: html`<p>Oops, Password cannot be empty. Try again</p>`,
  DuplicateEmailExitsException: html`<p>Oops, A User with that email already exists. Please use a different email address or sign in.</p>`,
  VerificationCodeResendSuccessful: html`<p>Verification code resent successfully, please check your email.</p>`,
  SignUpSuccessFul: html`<p>Welcome to StreamLabs</p>`,
  PasswordResetSuccessful: html`<p>Your password has been reset successfully.</p>`,
  PasswordUpdateSuccessful: html`<p>Your password has been updated successfully.</p>`,
  LimitExceededException: html`<p>Updating your password failed. Attempt limit exceeded, please try after some time.</p>`,
  PlanUpdateSuccessFul: html`<p>Your Plan <strong>updated</strong> successfully</p>`,
  PlanUpdateFail: html`<p>Updating your plan <strong>failed</strong>. Please contact customer support</p>`,
  PaymentSourceAddSuccessFul: html`<p>Your credit card was <strong>added</strong> successfully</p>`,
  PaymentSourceUpdateSuccessFul: html`<p>Your credit card was <strong>updated</strong> successfully</p>`,
  PaymentSourceDeleteSuccessFul: html`<p>Your credit card was <strong>removed</strong> successfully</p>`,
  PaymentSourceDeleteFail: html`<p>Removing your credit card <strong>failed</strong>. Please contact customer support</p>`,
  LoadAccountError: html`<p>We couldn't retrieve your account information. Please contact customer support</p>`,
  UsageDownloadedFailed: html`<p>We could not retrieve your water usage successfully. Please reach out to support@streamlabswater.com.</p>`
}
