import { html, render } from 'htm/preact'
import { Provider } from 'unistore/preact'
import ga from 'react-ga'
import store from 'services/store'
import { version } from '../package.json'
import 'index.css'
import App from 'modules/app'

ga.initialize('UA-104802044-3')
render(
  html`
  <${Provider} store=${store}>
    <${App} />
  </${Provider}>
  `,
  document.body
)

console.info('@streamlabs', {
  'build-env': process.env.STREAMLABS_CUSTOMER_APP_ENV,
  version
})
