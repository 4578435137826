import { startOfDay, formatISO } from 'date-fns'
import { stream, customer } from 'services/api'
import { formatCSVFile, exportFile } from './export.utils'
import { merge, dispatch } from 'services/utils'

export const locationsManager = {
  locations: {
    loaded: false,
    fetching: true,
    error: false,
    maxLocations: 0,
    list: []
  },
  usage: {
    selectedLocationIndx: 0,
    startDate: startOfDay(new Date()),
    endDate: new Date(),
    fetching: false,
    downloaded: false,
    error: false
  }
}

export const actions = (store) => {
  const loadLocations = async (state) => {
    store.setState({
      locations: {
        ...state.locations,
        loaded: false,
        fetching: true
      }
    })

    try {
      const { locations: list, maxLocations } = await stream.get('/locations')
      const prime = store.getState()
      const payload = {
        locations: {
          loaded: true,
          maxLocations,
          fetching: false,
          list
        }
      }
      return merge(prime, payload)
    } catch (err) {
      console.error('ERROR FETCHING LOCATIONS', err)
    }
  }

  const updateSelectedLocation = (state, indx) => merge(state, { usage: { selectedLocationIndx: parseInt(indx) } })

  const exportReadings = async (state) => {
    store.setState({
      usage: {
        ...state.usage,
        downloaded: false,
        fetching: true
      },
      notice: {
        type: '',
        code: ''
      }
    })

    try {
      const { selectedLocationIndx, startDate, endDate } = state.usage
      const location = state.locations.list[selectedLocationIndx]

      const { apiKey: token } = await stream.get('/subscription/api-key')

      const records = []
      let page = 1
      let keepGoing = true
      while (keepGoing) {
        const { pageCount, readings } = await customer.get(`/locations/${location.locationId}/readings/water-usage?startTime=${formatISO(startDate)}&endTime=${formatISO(endDate)}&perPage=100&page=${page}`, { token })

        records.push.apply(records, readings)

        if (page === pageCount) {
          keepGoing = false
          break
        }
        page++
      }

      exportFile(formatCSVFile(records), location.name, startDate, endDate)

      const prime = store.getState()
      return merge(prime, {
        usage: {
          downloaded: true,
          fetching: false
        }
      })
    } catch (err) {
      console.error('DOWNLOAD ERR', err)
      return merge(state, {
        usage: { error: true, fetching: false, downloaded: false },
        notice: { type: 'error', code: 'UsageDownloadedFailed' }
      })
    }
  }

  return { loadLocations, exportReadings, updateSelectedLocation, dispatch }
}
