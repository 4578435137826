import Cognito from '@aws-amplify/auth'
import { stream } from 'services/api'
import { merge, dispatch } from 'services/utils'

export const accountManager = {
  account: {
    loaded: false,
    fetching: true,
    views: new Set(),
    error: false
  },
  configs: {
    loaded: false,
    fetching: true,
    error: false,
    data: {
      help: {},
      endpoints: {},
      legal: {
        termsOfService: {
          lastUpdated: '',
          url: ''
        }
      }
    }
  },
  preferences: {
    loaded: false,
    fetching: true,
    error: false,
    data: {}
  }
}

export const actions = (store) => ({
  dispatch,
  loadAccount: async (state) => {
    const { configs, subscription } = state

    try {
      const { preferences: prefs } = await stream.get('/user/preferences')
      const confs = await stream.get('/config/web')
      const sub = await stream.get('/subscription')

      const needTerms = displayTerms(prefs, confs)
      const prime = store.getState()

      const payload = {
        account: {
          loaded: true,
          fetching: false,
          views: needTerms ? state.account.views.add('accept-terms') : state.account.views.add('preferences').add('subscription-details')
        },
        preferences: {
          loaded: true,
          fetching: false,
          data: prefs || {}
        },
        configs: {
          loaded: true,
          fetching: false,
          data: confs || configs.data
        },
        subscription: {
          loaded: true,
          fetching: false,
          data: sub || subscription.data
        }
      }

      return merge(prime, payload)
    } catch (err) {
      const payload = {
        account: {
          loaded: true,
          fetching: false,
          error: true
        },
        notice: {
          type: 'error',
          code: 'LoadAccountError'
        }
      }
      return merge(state, payload)
    }
  },
  updateUserPreferences: async (state, { body }) => {
    store.setState({
      preferences: {
        ...state.preferences,
        loading: false,
        fetching: true
      }
    })

    try {
      const { preferences: data } = await stream.post('/user/preferences/batch', { body })

      const prime = store.getState()
      const payload = {
        preferences: {
          loaded: true,
          fetching: false,
          error: false,
          data
        },
        account: {
          views: new Set(['preferences', 'subscription-details']),
          loaded: true,
          fetching: false
        }
      }
      return merge(prime, payload)
    } catch (err) {
      const { code } = err
      console.error('UPDTAE PREFS ERROR', err)
      const payload = {
        preferences: {
          error: true,
          loaded: true,
          fetching: false
        },
        notice: {
          type: 'error',
          code
        }
      }
      return merge(state, payload)
    }
  },
  changePassword: async (state, { password, newPassword }) => {
    if (!password) {
      return merge(state, {
        account: { error: true, fetching: false },
        notice: { type: 'error', code: 'NoCurrentPasswordProvidedException' }
      })
    }

    if (!newPassword) {
      return merge(state, {
        account: { error: true, fetching: false },
        notice: { type: 'error', code: 'NoPasswordProvidedException' }
      })
    }

    store.setState({
      ...merge(state, {
        account: { fetching: true, loaded: false, error: false },
        notice: { type: '', code: '', message: '' }
      })
    })

    try {
      const user = await Cognito.currentAuthenticatedUser()
      await Cognito.changePassword(user, password, newPassword)
      const prime = store.getState()
      prime.account.views.add('subscription-details').add('preferences').delete('change-password')

      return merge(prime, {
        account: {
          fetching: false,
          loaded: true,
          error: false
        },
        notice: {
          type: 'success',
          code: 'PasswordUpdateSuccessful'
        }
      })
    } catch ({ code }) {
      return merge(state, {
        account: { error: true, fetching: false, loaded: true },
        notice: { type: 'error', code }
      })
    }
  }
})

export function displayTerms (prefs, confs) {
  if (prefs.status === 'rejected' || confs.status === 'rejected') {
    return false
  }
  const tosLastAccepted = prefs.find(({ key }) => key === 'tosLastAccepted')
  const { legal: { termsOfService, privacy } } = confs

  if (!tosLastAccepted || !termsOfService || !privacy || termsOfService.lastUpdated > tosLastAccepted.value || privacy.lastUpdated > tosLastAccepted.value) {
    return true
  }
  return false
}
