import { getUnixTime, format } from 'date-fns'

export function formatCSVFile (readings) {
  const data = readings.map(({ time, volume }) => (`${format(new Date(time), 'MM/dd/yyyy')},${format(new Date(time), 'HH:mm')}, ${time}, ${volume}`)).join('\n')
  const csv = `Date,Time,Date Time (in ISO 8601),Volume (in gallons)\n${data}`

  return new window.Blob([csv], { type: 'text/csv' })
}

export function exportFile (data, name, startDate, endDate) {
  const anchor = document.createElement('a')
  const filename = `${name}.${getUnixTime(startDate)}-to-${getUnixTime(endDate)}.csv`.replace(/\s/g, '-')

  anchor.href = URL.createObjectURL(data)
  anchor.setAttribute('download', filename)
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
  setTimeout(() => {
  // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}
