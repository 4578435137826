import { stream } from 'services/api'
import { merge, dispatch } from 'services/utils'

export const subscriptionManager = {
  paymentMethod: {
    loaded: true,
    fetching: false,
    error: false,
    data: {}
  },
  subscription: {
    loaded: false,
    fetching: true,
    error: false,
    data: {}
  },
  cart: {
    plan: {},
    payment: {}
  }
}

export const actions = (store) => ({
  dispatch,
  loadPaymentMethod: async (state) => {
    store.setState({
      paymentMethod: {
        ...state.paymentMethod,
        loaded: false,
        fetching: true
      }
    })
    try {
      const data = await stream.get('/subscription/source')
      const prime = store.getState()
      const payload = {
        paymentMethod: {
          loaded: true,
          fetching: false,
          data,
          error: false
        }
      }
      return merge(prime, payload)
    } catch ({ code }) {
      const payload = {
        paymentMethod: {
          loaded: true,
          fetching: false,
          error: true
        },
        notice: {
          type: 'error',
          code
        }
      }
      return merge(state, payload)
    }
  },
  deletePaymentMethod: async (state) => {
    /**
     * Set fetching state for paymentMethod and subscription
     */
    store.setState({
      paymentMethod: { error: false, loaded: false, fetching: true },
      notice: { type: '', code: '', message: '' }
    })

    /**
     * Delete the payment and fetch a fresh subscription
     */
    try {
      await stream.delete('/subscription/source', {
        body: {
          provider: 'STRIPE',
          token: ''
        }
      })
      const data = await stream.get('/subscription')
      const prime = store.getState()

      const payload = {
        subscription: {
          data,
          loaded: true,
          fetching: false
        },
        paymentMethod: {
          data: {},
          loaded: true,
          fetching: false
        },
        notice: {
          type: 'success',
          code: 'PaymentSourceDeleteSuccessFul'
        }
      }
      return merge(prime, payload)
    } catch (err) {
      return merge(state, {
        subscription: {
          loaded: true,
          fetching: false
        },
        paymentMethod: {
          error: true,
          loaded: true,
          fetching: false
        },
        notice: {
          type: 'error',
          code: 'PaymentSourceDeleteFail'
        }
      })
    }
  },
  updateSubscription: async (state, body) => {
    store.setState({
      subscription: {
        ...state.subscription,
        loading: false,
        fetching: true
      }
    })

    try {
      const data = await stream.put('/subscription', { body })
      const prime = store.getState()

      const code = (prime.subscription.data.plan.planId !== data.plan.planId)
        ? 'PlanUpdateSuccessFul' : (!prime.subscription.data.sourcePresent && data.sourcePresent) ? 'PaymentSourceAddSuccessFul' : 'PaymentSourceUpdateSuccessFul'
      prime.account.views.add('subscription-details').add('preferences').delete('checkout')
      prime.account.views.delete('payment-form')

      return merge(prime, {
        subscription: {
          loaded: true,
          fetching: false,
          data
        },
        cart: {
          plan: {},
          payment: {}
        },
        notice: {
          type: 'success',
          code
        }
      })
    } catch (error) {
      state.account.views.add('subscription-details').delete('checkout')
      state.account.views.delete('payment-form')
      return merge(state, {
        subscription: {
          error: true,
          loaded: true,
          fetching: false
        },
        notice: {
          type: 'error',
          code: 'PlanUpdateFail'
        }
      })
    }
  }
})
