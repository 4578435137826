import Cognito from '@aws-amplify/auth'
import uuidv4 from 'uuid/v4'
import { merge, dispatch, getAuthenticatedUser } from 'services/utils'

export const authenticator = {
  user: {
    loaded: false,
    fetching: false,
    error: false,
    isAuthenticated: false,
    data: {
      id: '',
      email: '',
      username: '',
      groups: [],
      role: ''
    }
  },
  auth: {
    event: 'signin',
    data: {},
    loaded: true,
    fetching: false,
    error: false
  }
}

export const actions = (store) => ({
  dispatch,
  authenticate: async (state) => {
    try {
      const cognitoUser = await Cognito.currentAuthenticatedUser()
      const data = getAuthenticatedUser(cognitoUser)
      const prime = store.getState()

      const payload = {
        auth: {
          event: 'authenticated'
        },
        user: {
          data,
          isAuthenticated: true,
          loaded: true,
          fetching: false
        }
      }
      return merge(prime, payload)
    } catch (err) {
      const payload = {
        user: {
          loaded: true,
          fetching: false
        }
      }
      return merge(state, payload)
    }
  },
  signin: async (state, email, password) => {
    store.setState({
      ...merge(state, {
        auth: { fetching: true, loaded: false, error: false },
        notice: { type: '', code: '', message: '' }
      })
    })

    try {
      const cognitoUser = await Cognito.signIn(email, password)
      const data = getAuthenticatedUser(cognitoUser)
      const prime = store.getState()

      const payload = {
        auth: {
          event: 'authenticated',
          fetching: false,
          loaded: true
        },
        user: {
          data,
          isAuthenticated: true,
          loaded: true,
          fetching: false
        }
      }
      return merge(prime, payload)
    } catch ({ code }) {
      const payload = {
        auth: {
          error: true,
          fetching: false,
          loaded: true,
          data: { email }
        },
        notice: {
          type: 'error',
          code
        }
      }
      return merge(state, payload)
    }
  },
  requestPasswordToken: async (state, email, resend = false) => {
    store.setState({
      ...merge(state, {
        auth: { fetching: true, loaded: false, error: false },
        notice: { type: '', code: '', message: '' }
      })
    })

    try {
      await Cognito.forgotPassword(email)
      const prime = store.getState()

      const payload = {
        auth: {
          event: 'reset-password',
          loaded: true,
          fetching: false,
          data: { email }
        },
        notice: {
          type: 'info',
          code: (resend) ? 'VerificationCodeResendSuccessful' : 'VerificationCodeSendSuccessful'
        }
      }
      return merge(prime, payload)
    } catch ({ code }) {
      const payload = {
        auth: {
          loaded: true,
          fetching: false,
          error: true,
          data: { email }
        },
        notice: {
          type: 'error',
          code
        }
      }
      return merge(state, payload)
    }
  },
  resetPassword: async (state, verificationCode, newPassword) => {
    store.setState({
      ...merge(state, {
        auth: { fetching: true, loaded: false, error: false },
        notice: { type: '', code: '', message: '' }
      })
    })

    try {
      await Cognito.forgotPasswordSubmit(state.auth.data.email, verificationCode, newPassword)
      const cognitoUser = await Cognito.signIn(state.auth.data.email, newPassword)
      const data = getAuthenticatedUser(cognitoUser)
      const prime = store.getState()
      const payload = {
        auth: {
          event: 'authenticated',
          loaded: true,
          fetching: false
        },
        user: {
          data,
          loaded: true,
          fetching: false,
          isAuthenticated: true
        },
        notice: {
          type: 'success',
          code: 'PasswordResetSuccessful'
        }
      }
      return merge(prime, payload)
    } catch ({ code }) {
      const payload = {
        auth: {
          loaded: true,
          fetching: false,
          error: true
        },
        notice: {
          type: 'error',
          code
        }
      }
      return merge(state, payload)
    }
  },
  signUp: async (state, { email, password, acceptedTerms }) => {
    const username = uuidv4()

    if (!email) {
      return merge(state, {
        auth: { data: {}, error: true },
        notice: { type: 'error', code: 'InvalidEmailException' }
      })
    }
    if (!password) {
      return merge(state, {
        auth: { data: { email }, error: true },
        notice: { type: 'error', code: 'NoPasswordProvidedException' }
      })
    }

    if (!acceptedTerms) {
      return merge(state, {
        auth: { data: { email }, error: true },
        notice: { type: 'error', code: 'TermsNotAcceptedException' }
      })
    }

    store.setState({
      ...merge(state, {
        auth: { fetching: true, loaded: false, error: false },
        notice: { type: '', code: '', message: '' }
      })
    })

    try {
      await Cognito.signIn(email, 'p@55woRd')
    } catch (err) {
      const { code } = err
      if (code === 'UserNotFoundException') {
        try {
          await Cognito.signUp({ username, password, attributes: { email } })
          const prime = store.getState()

          return merge(prime, {
            auth: {
              event: 'confirm-signup',
              data: { username, email, password },
              error: false,
              fetching: false,
              loaded: true
            }
          })
        } catch (err) {
          const { code } = err
          return merge(state, {
            auth: {
              event: 'signup',
              data: { email },
              error: true,
              fetching: false,
              loaded: true
            },
            notice: {
              type: 'error',
              code
            }
          })
        }
      }

      if (code === 'NotAuthorizedException') {
        return merge(state, {
          auth: { event: 'signup', data: { email, password }, error: true },
          notice: { type: 'error', code }
        })
      }
    }
  },
  confirmSignUp: async (state, code) => {
    store.setState({
      ...merge(state, {
        auth: { fetching: true, loaded: false, error: false },
        notice: { type: '', code: '', message: '' }
      })
    })

    try {
      const { username, email, password } = state.auth.data
      await Cognito.confirmSignUp(username, code, { forceAliasCreation: false })
      const cognitoUser = await Cognito.signIn(email, password)
      const data = getAuthenticatedUser(cognitoUser)
      const prime = store.getState()

      const payload = {
        auth: {
          event: 'authenticated',
          loaded: true,
          fecthing: false
        },
        user: {
          data,
          isAuthenticated: true,
          loaded: true,
          fetching: false
        },
        notice: { type: 'success', code: 'SignUpSuccessFul' }
      }
      return merge(prime, payload)
    } catch ({ code }) {
      return merge(state, {
        auth: {
          event: 'confirm-signup',
          error: true,
          fetching: false,
          loaded: true
        },
        notice: { type: 'error', code }
      })
    }
  },
  resendSignUp: async (state) => {
    store.setState({
      ...merge(state, {
        auth: { fetching: true, loaded: false, error: false },
        notice: { type: '', code: '', message: '' }
      })
    })

    try {
      await Cognito.resendSignUp(state.auth.data.username)
      const prime = store.getState()
      return merge(prime, {
        auth: { fetching: false, loaded: true },
        notice: { type: 'success', code: 'VerificationCodeResendSuccessful' }
      })
    } catch ({ code }) {
      return merge(state, {
        auth: { error: true, fetching: false, loaded: true },
        notice: { type: 'error', code }
      })
    }
  }
})
