import { html } from 'htm/preact'
import { connect } from 'unistore/preact'
import Cognito from '@aws-amplify/auth'
import { actions } from 'modules/auth/redux'
import { origin } from 'services/store'

const Navigation = ({ user, account, dispatch }) => {
  async function handleSignOut (ev) {
    ev.preventDefault()
    try {
      await Cognito.signOut()
      dispatch({ ...origin })
      window.location.pathname = '/auth'
    } catch (err) {
      console.error('SIGNOUT', err)
    }
  }

  function handleChangePassword (ev) {
    ev.preventDefault()
    account.views.clear()
    account.views.add('change-password')

    dispatch({
      account,
      notice: { type: '', code: '', message: '' }
    })
  }
  if (!user.isAuthenticated) return null

  return html`
    <nav>
   
      ${!account.views.has('accept-terms') && html`
        <a href='#change-password' onClick=${handleChangePassword}>
          Change Password
        </a>
      `}
      <a href='#signout' id='sign-out' onClick=${handleSignOut}> Sign Out </button>
    </nav>
  `
}

export default connect(['user', 'account'], actions)(Navigation)
