import aws from './aws'
import Api from '@aws-amplify/api'

Api.configure(aws.amplifyConfig)

/**
 * STEAM_API Client
 * @type {Object}
 */
export const stream = {
  get: (path) => Api.get('stream-api', path),
  post: (path, init = {}) => Api.post('stream-api', path, init),
  put: (path, init = {}) => Api.put('stream-api', path, init),
  delete: (path, init = {}) => Api.del('stream-api', path, init)
}

export const customer = {
  baseUrl: process.env.STREAMLABS_CUSTOMER_APP_CUSTOMER_API_HOST,
  headers: async (apiKey) => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${apiKey}`
  }),
  get: async function (path, { token }) {
    const url = `${this.baseUrl}${path}`
    const headers = await this.headers(token)
    const response = await fetch(url, { headers })

    return respond(response)
  }
}

function respond (response) {
  const { status } = response
  if (response.ok) {
    return response.json()
  }
  throw new FetchException({ code: status })
}

export function FetchException ({ code, message }) {
  this.code = code
  this.message = message
  this.toString = () => (this.message)
}
