import devtools from 'unistore/devtools'
import createStore from 'unistore'
import Cognito from '@aws-amplify/auth'
import aws from './aws'
import { authenticator } from 'modules/auth/redux'
import { accountManager } from 'modules/account/redux'
import { subscriptionManager } from 'modules/subscription/redux'
import { locationsManager } from 'modules/export-manager/redux'
import { notice } from 'modules/notification/redux'

Cognito.configure(aws.amplifyConfig)

export const origin = {
  ...authenticator,
  ...accountManager,
  ...subscriptionManager,
  ...locationsManager,
  notice
}

const store = process.env.NODE_ENV === 'development' ? devtools(createStore({ ...origin })) : createStore({ ...origin })

export default store
