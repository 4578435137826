import { html } from 'htm/preact'
import { format } from 'date-fns'
import { version } from '../../../package.json'

const Footer = () => html`
  <footer>
    <aside id='primary'>
      <ul className='card'>
        <li><a href='https://www.streamlabswater.com/monitor' target='_blank' rel='noopener noreferrer'>Monitor</a></li>
        <li><a href='https://www.streamlabswater.com/control' target='_blank' rel='noopener noreferrer'>Control</a></li>
        <li><a href='https://www.streamlabswater.com/accessories' target='_blank' rel='noopener noreferrer'>Accessories</a></li>
      </ul>
      <ul className='card'>
        <li><a href='https://support.streamlabswater.com/hc/en-us' target='_blank' rel='noopener noreferrer'>Support Home</a></li>
        <li><a href='https://support.streamlabswater.com/hc/en-us/categories/115001512827-FAQ' target='_blank' rel='noopener noreferrer'>FAQ</a></li>
        <li><a href='https://support.streamlabswater.com/hc/en-us/categories/115001510848-Troubleshooting' target='_blank' rel='noopener noreferrer'>Troubleshooting</a>
        </li>
      </ul>
      <div className='badges'>
        <a href='https://itunes.apple.com/US/app/id1217865938?mt=8' target='_blank' rel='noopener noreferrer' ><img src='/assets/apple-app-store.svg' alt='' /></a>
        <a href='https://play.google.com/store/apps/details?id=com.streamlabs.stream.abc&hl=en' target='_blank' rel='noopener noreferrer'><img src='/assets/google-play-store.svg' alt='' /></a>
      </div>
    </aside>
    <aside id='info'>
        Copyright<sup>\u00A9</sup> ${format(new Date(), 'y')} all rights reserved by <a href='https://streamlabswater.com' target='_blank' rel='noopener noreferrer'>StreamLabs<sup>\u00AE</sup></a>
      . <a href='https://support.streamlabswater.com/hc/en-us/articles/115001969994-Product-Agreement-and-Terms-of-Service' target='_blank' rel='noopener noreferrer'>Terms of Service</a> . <a href='https://support.streamlabswater.com/hc/en-us/articles/115001972013-Privacy-Policy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a> . v${version}
    </aside>
  </footer>
`

export default Footer
