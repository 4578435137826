import { html, Component } from 'htm/preact'
import { Router, route } from 'preact-router'
import Home from 'routes/home'
import Auth from 'routes/auth'

export default class AppProvider extends Component {
  constructor (props) {
    super(props)
    if (!props.user.loaded) {
      props.authenticate()
    }
  }

  handleRoute = ({ url }) => {
    const { isAuthenticated, loaded } = this.props.user
    const pages = { '/': 'home', '/auth': 'auth' }

    document.body.dataset.page = pages[url]
    if (url !== '/auth' && !isAuthenticated && loaded) return route('/auth', true)
  }

  componentDidUpdate (prevProps) {
    const { pathname } = window.location
    if (!prevProps.user.isAuthenticated && this.props.user.isAuthenticated && pathname === '/auth') {
      route('/', true)
    }
  }

  render ({ user }) {
    if (!user.loaded) return null

    return html`
      <${Router} onChange=${this.handleRoute}>
        <${Auth} path='/auth' />
        <${Home} path='/' />
      </${Router}>
    `
  }
}
