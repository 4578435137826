import { merge } from 'services/utils'

export const notice = {
  type: '',
  code: ''
}

export const actions = {
  clear: (state) => merge(state, {
    auth: { error: false },
    user: { error: false },
    subscription: { error: false },
    notice: { type: '', code: '' }
  })
}
